<template>
    <div class="type">
        <div class="type-title">{{ "分类" | language }}：</div>
        <div class="type-list" :class="{ active: show }">
            <div
                class="type-item click-op"
                @click="handleClick(item)"
                v-for="item in types"
                :key="item._id"
            >
                {{ item.name | language }}
            </div>
        </div>
        <Icon
            class="type-btn"
            v-if="types.length > 5"
            type="ios-arrow-down"
            :class="{ 'btn-click': show }"
            @click="show = !show"
        />
    </div>
</template>

<script>
import { languageTranslation } from "../../../assets/util/language";
export default {
    data() {
        return {
            selectId: "",
            types: [],
            show: false
        };
    },
    mounted() {
        this.fetchTypes();
    },
    methods: {
        handleClick(item) {
            if (item.isDeepMost) {
                // 到底
                // if (!this.select.length) {
                //   this.select.push(item);
                // } else if (
                //   this.select.length &&
                //   this.select[this.select.length - 1]._id !== item._id
                // ) {
                //   this.select.push(item);
                // } else {
                this.$Message.error(
                    languageTranslation("已经是最底层的分类啦")
                );
                // }

                this.types = [item];
            } else {
                // this.select.push(item);
                this.selectId = item._id;

                this.fetchTypes();
            }
            this.$emit("handleClick", item._id);
        },
        refetchCategory(id) {
            // if (!item) {
            //   this.select = [];
            // } else {
            //   this.select.splice(index + 1);
            // }
            this.selectId = id;
            // console.log(index);
            this.fetchTypes();
        },
        async fetchTypes() {
            let url = "/device/category";
            if (this.selectId) {
                url = `/device/category?categoryId=${this.selectId}`;
            }
            let res = await this.$http.get(url);
            if (res.length) {
                this.types = res;
                console.log(res);
            }
        }
    }
};
</script>

<style lang="less" scoped>
.type {
    padding-bottom: 15px;
    // width: 808px;
    display: flex;
    align-items: flex-start;
    font-size: 16px;

    user-select: none;

    .type-title {
        height: 22px;
        font-size: 16px;
        // color: rgba(44, 141, 252, 1);
        line-height: 22px;
        color: #000;
        opacity: 0.6;
    }
    .type-list {
        // margin-left: -20px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        // max-width: 700px;
        height: 22px;
        overflow: hidden;
        transition: all linear 0.3s;

        .type-item {
            color: #000;
            opacity: 0.6;
            min-width: 100px;
            text-align: left;
            line-height: 22px;
            margin-left: 40px;
            text-align: center;

            &:hover {
                cursor: pointer;
                color: #2c8dfc;
                opacity: 1;
            }
        }
    }
    .active {
        height: auto;
        color: #2c8dfc;
        opacity: 1;
    }
    .type-btn {
        font-size: 22px;
        transform: rotate(0);
        transition: all linear 0.3s;
    }
    .btn-click {
        transform: rotate(-180deg);
    }
}
@media screen and (max-width: 960px) {
    .type {
        padding-bottom: 10px;
        .type-title {
            flex-shrink: 0;
        }
        .type-list {
            .type-item {
                margin-left: 10px;
                min-width: 50px;
            }
        }
    }
}
</style>
