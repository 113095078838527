class SimpleBus {
    constructor() {
        this.bus = {};
    }
    $on(event, callback) {
        this.bus[event] = callback;
    }
    $emit(event, ...args) {
        let callback = this.bus[event];
        if (!callback) {
            return console.error('没有找到callback函数', event);
        }
        callback.apply(this, args);
    }
}

// 用来做全局事件通知
export let globalBus = new SimpleBus();