<template>
    <div class="item" v-if="item && Object.keys(item).length">
        <div class="title ellipse">{{ item.name }}</div>
        <div class="right">
            <div class="content">
                {{ "类别" | language }}：{{ item.categoryName }}
            </div>
            <div class="content">
                {{ "编号" | language }}：{{ item.deviceNo }}
            </div>
            <div class="content">{{ "规格" | language }}：{{ item.spec }}</div>
            <div class="content">{{ "品牌" | language }}：{{ item.band }}</div>
        </div>
    </div>
</template>

<script>
import { languageTranslation } from "../../../assets/util/language";
export default {
    props: {
        item: Object,
        select: Array
    },
    data() {
        return {};
    },
    computed: {},
    methods: {
        goToDetail(item) {
            if (item.status == "available") {
                this.$store.commit("set_device_type", this.select);
                return this.$router.push(
                    `/base/service/device/detail/${item._id}`
                );
            } else {
                this.$Message.error(languageTranslation("不可预约"));
            }
        },
        btnText(item) {
            if (item.status == "available") {
                return {
                    show: true,
                    text: "立即预约"
                };
            } else if (item.status == "suspend") {
                return { show: false, text: "暂停预约" };
            } else if (item.status == "outstock") {
                return {
                    show: false,
                    text: "暂无库存"
                };
            }
        }
    }
};
</script>

<style lang="less" scoped>
.item {
    width: 235px;
    //   height: 220px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.18);
    padding: 30px 40px;
    .title {
        height: 22px;
        font-size: 16px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
        line-height: 22px;
        margin-bottom: 20px;
    }

    .content {
        margin-bottom: 12px;
        width: 100%;
        height: 20px;
        font-size: 14px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        line-height: 20px;
        text-align: left;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}
</style>
