<template>
    <!-- 设备预约页面 -->

    <div class="device">
        <div class="category">
            <Category @handleClick="changeCategory" ref="category"></Category>
        </div>
        <div class="notice click-op mr-t1" @click="goToNotice">
            <p>{{ "预约须知" | language }}</p>
            <img src="../../assets/img/across-right.png" alt="" />
        </div>
        <div class="common-list">
            <div class="current-title">{{ "类别" | language }}：</div>
            <div
                class="common-item"
                :class="{ active: !canReserve }"
                @click="canReserveChange(false)"
            >
                {{ "常用设备" | language }}
            </div>
            <div
                class="common-item mr-l1"
                :class="{ active: canReserve }"
                @click="canReserveChange(true)"
            >
                {{ "可预约设备" | language }}
            </div>
        </div>
        <div class="container">
            <div class="left">
                <div class="current">
                    <div class="current-title">{{ "当前" | language }}：</div>
                    <div
                        class="text click-op"
                        :class="{ select: breadArr.length >= 1 }"
                        @click="getCategory(null)"
                    >
                        {{ "全部设备" | language
                        }}<span v-if="breadArr.length"> ></span>
                    </div>
                    <template v-if="breadArr.length">
                        <div
                            class="text click-op"
                            v-for="(item, index) in breadArr"
                            :key="item._id"
                            @click="getCategory(item._id)"
                        >
                            {{ item.name | language }}
                            <span v-if="breadArr.length - 1 !== index"> ></span>
                        </div>
                    </template>
                </div>
                <div class="device-list" v-if="list.length && !canReserve">
                    <device-common-item
                        class="device-common-item"
                        v-for="item in list"
                        :key="item._id"
                        :item="item"
                        :select="breadArr"
                    ></device-common-item>
                </div>
                <div class="device-list" v-if="list.length && canReserve">
                    <device-item
                        class="device-item"
                        v-for="item in list"
                        :key="item._id"
                        :item="item"
                        :select="breadArr"
                    ></device-item>
                </div>
                <Page
                    :total="total"
                    :current="pageNum"
                    :page-size="pageSize"
                    @on-change="handleChange"
                    style="margin-bottom:40px;"
                    show-total
                >
                    <span class="ivu-page-total" style="font-size:16px;"
                        >{{ "共有" | language }} {{ total }}
                        {{ "条" | language }}</span
                    >
                </Page>
            </div>

            <div class="right">
                <Search @search="handleSearch"></Search>
            </div>
        </div>
    </div>
</template>

<script>
import Category from "../../compontents/service/device/Category.vue";
import DeviceItem from "../../compontents/service/device/DeviceItem.vue";
import DeviceCommonItem from "../../compontents/service/device/DeviceCommonItem.vue";
import Search from "../../compontents/service/device/Search.vue";
import { fetchLanguage } from "../../assets/util/language";
import { globalBus } from "../../assets/util/event";
export default {
    components: {
        Category,
        DeviceItem,
        DeviceCommonItem,
        Search
    },
    data() {
        return {
            // select: [],
            keyword: "",
            pageSize: 10,
            pageNum: 1,
            list: [],
            canReserve: true,
            total: 0,
            breadArr: []
        };
    },
    mounted() {
        let { keyword, categoryId } = this.$route.query;
        if (keyword) {
            this.keyword = keyword;
        }

        if (categoryId) {
            this.getCategory(categoryId);
        } else {
            this.getCategory(null);
        }
    },
    methods: {
        canReserveChange(canReserve) {
            this.list = [];
            this.canReserve = canReserve;

            this.pageNum = 1;
            this.fetchDevice();
        },
        handleSearch(val) {
            this.keyword = val;
            this.pageNum = 1;
            this.fetchDevice();
        },
        //点击菜单分类方法
        async changeCategory(id) {
            await this.getBread(id);
            this.pageNum = 1;
            this.fetchDevice();
        },
        //获取面包屑
        async getBread(id) {
            let res = await this.$http.get(
                `/device/category/ancestor?categoryId=${id}`
            );
            this.breadArr = res;
        },
        //点击导航纸屑
        getCategory(id, index) {
            if (id) {
                this.getBread(id);
            } else {
                this.breadArr = [];
            }
            this.$refs.category.refetchCategory(id);
            // this.select.splice(index);
            this.pageNum = 1;
            this.fetchDevice();
        },
        //分页
        handleChange(page) {
            this.pageNum = page;
            this.fetchDevice();
        },
        goToNotice() {
            if (this.$route.path == "/base/service/device/notice") return;
            this.$router.push("/base/service/device/notice");
        },
        //获取商品
        async fetchDevice() {
            this.$Spin.show();
            if (this.canReserve) {
                this.pageSize = 10;
            } else {
                this.pageSize = 9;
            }
            let id;
            if (!this.breadArr.length) {
                id = "";
            } else {
                id = this.breadArr[this.breadArr.length - 1]._id;
            }
            let res = await this.$http.get(
                `/device/summary/?categoryId=${id}&canReserve=${
                    this.canReserve
                }&keyword=${this.keyword}&pageSize=${
                    this.pageSize
                }&pageNum=${this.pageNum - 1}`
            );
            this.list = res.result;
            this.total = res.total;
            this.$Spin.hide();
        }
    }
};
</script>

<style lang="less" scoped>
.ivu-input {
    background-color: rgba(0, 0, 0, 0.1);
}
.device {
    margin-left: 20px;
    width: 1022px;
    .category {
        border-bottom: 1px solid #d5d7da;
    }
    .notice {
        display: flex;
        align-items: center;
        color: #2c8dfc;
        p {
            height: 20px;
            font-size: 16px;

            font-weight: 600;
            color: rgba(44, 141, 252, 1);
            line-height: 20px;
        }
        img {
            margin-left: 10px;
        }
    }
    .common-list {
        margin-top: 20px;
        display: flex;
        font-size: 16px;
        height: 29px;
        line-height: 29px;
        user-select: none;
        .current-title {
            color: #000;
            opacity: 0.6;
        }
        .common-item {
            color: #000;
            opacity: 0.6;
            &:hover {
                opacity: 1;
                cursor: pointer;
                color: #0091ff;
            }
        }
        .active {
            opacity: 1;
            cursor: pointer;
            color: #0091ff;
        }
    }
    .container {
        margin-top: 20px;
        width: 1022px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        .left {
            width: 808px;
            .current {
                display: flex;
                font-size: 16px;
                height: 29px;
                user-select: none;
                line-height: 29px;
                .current-title {
                    color: #000;
                    opacity: 0.6;
                    // color: #0091ff;
                }
                .text {
                    margin-left: 15px;
                    color: #000;
                    opacity: 0.6;
                    &:hover {
                        cursor: pointer;
                    }
                }
                .select {
                    color: rgba(0, 0, 0, 0.5);
                }
            }
            .device-list {
                //808
                width: 780px;
                display: flex;
                margin-top: 20px;

                align-items: center;
                flex-wrap: wrap;
                .device-item {
                    margin-right: 25px;
                    margin-bottom: 25px;
                    &:nth-child(2n) {
                        margin-right: 0;
                    }
                }
                .device-common-item {
                    margin-right: 25px;
                    margin-bottom: 25px;
                    &:nth-child(3n) {
                        margin-right: 0;
                    }
                    &:hover {
                        cursor: pointer;
                        user-select: none;
                    }
                }
            }
        }
        .right {
            width: 170px;
            .notice {
                display: flex;
                align-items: center;
                color: #2c8dfc;
                p {
                    height: 20px;
                    font-size: 14px;

                    font-weight: 600;
                    color: rgba(44, 141, 252, 1);
                    line-height: 20px;
                }
                img {
                    margin-left: 10px;
                }
            }
            .search-title {
                width: 100%;
                text-align: left;
                margin-top: 20px;
                height: 20px;
                font-size: 14px;

                font-weight: 600;
                color: rgba(0, 0, 0, 0.85);
                line-height: 20px;
            }
            .hot-list {
                width: 100%;
                margin-top: 20px;
                .hot-item {
                    display: flex;
                    align-items: center;

                    margin-bottom: 10px;
                    .hot-index {
                        width: 18px;
                        height: 18px;
                        background: rgba(248, 119, 119, 1);
                        height: 18px;
                        font-size: 10px;
                        font-weight: 600;
                        color: rgba(255, 255, 255, 1);
                        line-height: 18px;
                    }
                    .hot-text {
                        max-width: 126px;
                        margin-left: 20px;
                        height: 18px;
                        font-size: 12px;

                        font-weight: 600;
                        color: rgba(44, 141, 252, 1);
                        line-height: 18px;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 960px) {
    .device {
        margin-left: 0;
        width: 100%;
        .common-list {
            margin-top: 10px;
        }
        .container {
            width: 100%;
            margin-top: 10px;
            .left {
                width: 100%;
                .device-list {
                    width: 100%;
                    .device-item {
                        margin-right: 0;
                        width: 100%;
                    }
                }
            }
            .right {
                display: none;
            }
        }
    }
}
</style>
